var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"plan-form"}},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{ref:"filters",attrs:{"title":"Addons"}},[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('br'),_c('span',[_vm._v("Carregando...")])],1):_vm._e(),(!_vm.isLoading)?_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo do Intervalo","label-for":"interval_type"}},[_c('validation-provider',{attrs:{"name":"interval_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"interval_type","options":_vm.intervalTypeOptions,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.additonalData.interval_type),callback:function ($$v) {_vm.$set(_vm.additonalData, "interval_type", $$v)},expression:"additonalData.interval_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4145991892)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Quantidade do Intervalo","label-for":"interval_quantity"}},[_c('validation-provider',{attrs:{"name":"interval_quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"number","raw":false,"options":_vm.cleaveOptions.numeral,"placeholder":"2","disabled":_vm.isSubmiting},model:{value:(_vm.additonalData.interval_quantity),callback:function ($$v) {_vm.$set(_vm.additonalData, "interval_quantity", $$v)},expression:"additonalData.interval_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3471285556)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.additonalData.name),callback:function ($$v) {_vm.$set(_vm.additonalData, "name", $$v)},expression:"additonalData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3035178075)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Preço","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"disabled":_vm.isLoading || _vm.isSubmiting},model:{value:(_vm.additonalData.price),callback:function ($$v) {_vm.$set(_vm.additonalData, "price", $$v)},expression:"additonalData.price"}},'money',{ decimal: ',', thousands: '.', prefix: 'R$ ', precision: 2, masked: false },false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,74035333)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:{ 'disabled': _vm.isSubmiting },attrs:{"id":"description","rows":"10","disabled":_vm.isSubmiting,"options":_vm.editorOption},model:{value:(_vm.additonalData.description),callback:function ($$v) {_vm.$set(_vm.additonalData, "description", $$v)},expression:"additonalData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2273004573)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Características","label-for":"description"}},[_vm._v(" "+_vm._s(_vm.additonalData.features)+" ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Dependências","label-for":"dependencies"}},[_vm._v(" "+_vm._s(_vm.additonalData.dependencies)+" ")])],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-buttons",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Voltar ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isSubmiting},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" "+_vm._s(_vm.isUpdating() ? 'Alterar' : 'Incluir')+" "),(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }