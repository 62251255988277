<template>
  <section id="plan-form">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card
          ref="filters"
          title="Addons"
        >
          <div
            v-if="isLoading"
            class="text-center"
          >
            <b-spinner />
            <br>
            <span>Carregando...</span>
          </div>

          <div v-if="!isLoading">
            <validation-observer ref="simpleRules">
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Tipo do Intervalo"
                      label-for="interval_type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="interval_type"
                        rules="required"
                      >
                        <b-form-select
                          id="interval_type"
                          v-model="additonalData.interval_type"
                          :options="intervalTypeOptions"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Quantidade do Intervalo"
                      label-for="interval_quantity"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="interval_quantity"
                        rules="required"
                      >
                        <cleave
                          id="number"
                          v-model="additonalData.interval_quantity"
                          class="form-control"
                          :raw="false"
                          :options="cleaveOptions.numeral"
                          placeholder="2"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required|min:3"
                      >
                        <b-form-input
                          id="name"
                          v-model="additonalData.name"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Preço"
                      label-for="price"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="price"
                        rules="required"
                      >
                        <money
                          v-model="additonalData.price"
                          class="form-control"
                          v-bind="{ decimal: ',', thousands: '.', prefix: 'R$ ', precision: 2, masked: false }"
                          :disabled="isLoading || isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!--                <b-row>-->
                <!--                  <b-col md="6">-->
                <!--                    <b-form-group-->
                <!--                      label="Características (informar uma por linha)"-->
                <!--                      label-for="features"-->
                <!--                    >-->
                <!--                      <validation-provider-->
                <!--                        #default="{ errors }"-->
                <!--                        name="features"-->
                <!--                        rules="required"-->
                <!--                      >-->
                <!--                        <b-form-textarea-->
                <!--                          id="features"-->
                <!--                          v-model="additonalData.features"-->
                <!--                          rows="10"-->
                <!--                          :state="errors.length > 0 ? false:null"-->
                <!--                          :disabled="isSubmiting"-->
                <!--                        />-->
                <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                <!--                      </validation-provider>-->
                <!--                    </b-form-group>-->
                <!--                  </b-col>-->
                <!--                </b-row>-->

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Descrição"
                      label-for="description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Descrição"
                        rules="required"
                      >
                        <quill-editor
                          id="description"
                          v-model="additonalData.description"
                          :class="{ 'disabled': isSubmiting }"
                          rows="10"
                          :disabled="isSubmiting"
                          :options="editorOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Características"
                      label-for="description"
                    >
                      {{ additonalData.features }}
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Dependências"
                      label-for="dependencies"
                    >
                      {{ additonalData.dependencies }}
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="12"
                    class="form-buttons"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="$router.go(-1)"
                    >
                      Voltar
                    </b-button>

                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="isSubmiting"
                      @click="validationForm()"
                    >
                      {{ isUpdating() ? 'Alterar' : 'Incluir' }}
                      <b-spinner
                        v-if="isSubmiting"
                        small
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </section>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BButton,
  BBreadcrumb,
  BSpinner,
} from 'bootstrap-vue'
import { Money } from 'v-money'
import Cleave from 'vue-cleave-component'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toast from '@/mixins/toast'
import requestErrorMessage from '@/mixins/requestErrorMessage'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import CommerciaPlanService from '@/services/commerciaPlanService'
import { commerciaPlanAdditionalIntervalType } from '@/utils/options'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Money,
    Cleave,
    BFormInput,
    BFormGroup,
    BForm,
    BFormSelect,
    BButton,
    BBreadcrumb,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    quillEditor,
  },
  directives: {
    // Ripple,
  },
  mixins: [
    toast, requestErrorMessage,
  ],
  props: {

  },
  data() {
    return {
      isLoading: true,
      isSubmiting: false,
      cleaveOptions: {
        numeral: {
          numeral: true,
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralPositiveOnly: true,
        },
      },
      editorOption: {
        syntax: false,
        placeholder: 'Mensagem',
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link'],
          ],
        },
      },
      breadcrumb: [
        {
          text: 'CMS',
          active: true,
        },
        {
          text: 'Commercia',
          active: true,
        },
        {
          text: 'Planos',
          to: { name: 'cms-commercia-plans' },
        },
        {
          text: 'Addons',
          to: { name: 'cms-commercia-plan-additionals' },
        },
        {
          text: this.isUpdating() ? 'Alterar' : 'Adicionar',
          active: true,
        },
      ],
      additonalData: {
        id: null,
        name: '',
        description: '',
        interval_type: '',
        interval_quantity: '',
        price: '',
        features: [],
      },
      intervalTypeOptions: commerciaPlanAdditionalIntervalType,
      required,
    }
  },

  async created() {
    if (this.isUpdating()) {
      this.getAdditional()
    } else {
      this.isLoading = false
    }
  },

  methods: {
    isUpdating() {
      return this.$route.params.id !== undefined
    },

    getAdditional() {
      this.isLoading = true
      CommerciaPlanService.getAdditional(this.$route.params.id).then(response => {
        if (response.data !== null) {
          this.additonalData = response.data.data.additional
        }
        this.isLoading = false
      })
    },

    postAdditional() {
      this.isSubmiting = true

      const data = this.additonalData

      if (this.isUpdating()) {
        CommerciaPlanService.updateAdditional(this.$route.params.id, data).then(response => {
          if (response.status) {
            this.toastSuccess('Addon alterado')
            this.$router.go(-1)
          } else {
            this.toastDanger('Erro!', 'Não foi possível alterar o addon!')
          }
        }).catch(error => {
          this.toastDanger('Erro!', this.getResponseErrorMessage(error))
        }).finally(() => {
          this.isSubmiting = false
        })
      } else {
        CommerciaPlanService.addAdditional(data).then(response => {
          if (response.status) {
            this.toastSuccess('Addon adicionado!')
            this.$router.go(-1)
          } else {
            this.toastDanger('Erro!', 'Não foi possível alterar o addon!')
          }
        }).catch(error => {
          this.toastDanger('Erro!', this.getResponseErrorMessage(error))
        }).finally(() => {
          this.isSubmiting = false
        })
      }
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postAdditional()
        } else {
          this.toastDanger('Ops!', 'Existem erros de validação no formulário')
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #plan-form{
      #description {
        height: 350px;
        padding-bottom: 45px;

        &.disabled{
          background-color: $input-disabled-bg;
        }
      }
  }

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
